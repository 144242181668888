/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~angular-notifier/styles.css";
/*@import '@angular/material/prebuilt-themes/deeppurple-amber.css';*/
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~material-design-icons/iconfont/material-icons.css';
/*@import '@angular/material/_theming';*/
/*@import 'node_modules/@angular/material/theming';*/
@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

body {
  background: #EFF5FF !important;
}

.app-heading {
  padding-left: 24px !important;
  margin: 30px 0 12px 0 !important;
}

.theme-icon-close:before {
  content: 'X'
}

.ui-table-frozen-view, .ui-table-scrollable-view {
  display: inline-block;
  float: left;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  padding: 1em;
}

.medium-cell {
  width: 75px;
}

.small-cell {
  width: 50px;
}

.item-color {
  height: 30px;
  width: 30px;
  display: block;
  border: 1px solid #000;
}

.option-item {
  padding: 2px 5px;
}

  .option-item > span {
    width: 25px;
    display: inline-block;
  }


.scroll-table {
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
}

.table-container .table-header {
  margin-bottom: 10px;
}

.table-container .dropdown {
  margin-bottom: 10px;
}

.table-container table th .icon-btn {
  float: right;
}

.table-container .icon-btn {
  cursor: pointer;
  margin-right: 3px;
}

.table-container .dropdown-menu > div, .table-container .sort-column {
  cursor: pointer;
}

.table-container .dropdown-menu > div {
  padding: 2px 5px;
}

.table-container .grey-btn {
  color: #ccc;
}

.table-container .grey-btn {
  color: #000;
}

.table-container .sort-column > span {
  position: relative;
  margin-left: 10px;
}

  .table-container .sort-column > span > i:first-child {
    position: absolute;
    top: 0;
  }

  .table-container .sort-column > span > i:last-child {
    position: absolute;
    bottom: -3px;
  }

table .active-column {
  background: #ddedfd !important;
}

.table.small-txt {
  font-size: 12px;
}

.tablesmall-txt td {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.table.small-txt th {
  text-align: left;
  vertical-align: middle;
}

.dialog {
  position: fixed;
  min-width: 40vw;
  max-width: 40vw;
  min-height: 70vh;
  max-height: 70vh;
  top: 100px;
  z-index: 1000;
  background: #fff;
  left: 50%;
  margin-left: -350px;
  border-radius: 5px;
}

.dialog-dashboard {
  position: fixed;
  top: 10em;
  z-index: 1000;
  background: #fff;
  left: 47%;
  margin-left: -350px;
  border-radius: 5px;
  width: 50em;
}

.dialog-tree {
  position: fixed;
  width: 80vw;
  max-height: 80vh;
  min-height: 80vh;
  top: 10vh;
  z-index: 1000;
  background: #fff;
  left: 10vw;
  border-radius: 5px;
}

.dialog-color {
  position: fixed;
  width: 20vw;
  max-height: 20vh;
  min-height: 20vh;
  top: 10vh;
  z-index: 1000;
  background: #fff;
  left: 10vw;
  border-radius: 5px;
}

.dialog header {
  line-height: 30px;
  padding: 5px 10px;
  font-weight: bold;
  background: #00a8ff;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

  .dialog header .cancel-btn {
    display: inline-block;
    float: right;
    width: 30px;
    text-align: center;
    cursor: pointer;
  }

.dialog section {
  height: 350px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
}

.dialog-large {
  position: fixed;
  min-width: 70vw;
  max-width: 70vw;
  min-height: 80vh;
  max-height: 80vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: #fff;
  border-radius: 5px;
}

  .dialog-large header {
    line-height: 1vh;
    padding: 4vh;
    font-weight: bold;
    background: #20488A;
    color: #fff;
    border-radius: 5px 5px 0 0;
  }

    .dialog-large header .cancel-btn {
      display: inline-block;
      float: right;
      width: 30px;
      text-align: center;
      cursor: pointer;
    }

  .dialog-large section {
    height: 77vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 1vh;
  }

.confirm-dialog {
  position: fixed;
  left: 50%;
  top: 22%;
  min-width: 40vw;
  max-width: 40vw;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 100;
  padding: 1vh;
  border-radius: 1vh;
  text-align: center
}

  .confirm-dialog .confirm-header {
    width: 100%;
    padding: 0px;
    text-align: center;
    font-size: 3vh;
  }

  .confirm-dialog .confirm-options {
    width: 100%;
    padding: 0px;
    text-align: center;
    display: block;
    min-height: 6vh;
    bottom: 100%;
  }

  .confirm-dialog button {
    font-size: 2vh;
    margin-bottom: 0.5vh;
    margin: .25vw;
  }

.filter-menu {
  position: relative;
  margin-top: 2vh;
  margin: auto;
  width: 30vw;
  min-height: 40vh;
  background-color: white;
  z-index: 100;
  border: solid 2px blue;
  padding: 20px;
  border-radius: 25px;
}

.mat-tooltip {
  font-size: 12px;
  white-space: pre-line;
}

.filter-menu .cancel-btn {
  z-index: 101;
  float: right;
  min-height: 50px;
  min-width: 50px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.dialog-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
}

.mtd-compare-new-value {
  display: block;
  text-align: center;
  font-size: 12px;
}

.mtd-compare-old-value {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  float: right;
}

.mtd-compare-mutiply-const {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  float: left;
}

.dialog.multi-cols {
  top: 70px;
}

  .dialog.multi-cols section {
    height: 500px;
  }

  .dialog.multi-cols > section {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

    .dialog.multi-cols > section > div {
      overflow: hidden;
      padding: 10px 10px 5px 5px;
      border-right: 1px solid #ccc;
    }

.dialog.cols-1 > section > div {
  width: 600px;
  border: none;
}

.dialog.cols-2 {
  width: 800px;
  margin-left: -400px;
}

  .dialog.cols-2 > section > div {
    flex: 0 0 50%;
    max-width: 50%;
  }

.dialog.cols-3 {
  width: 900px;
  margin-left: -450px;
}

  .dialog.cols-3 > section > div {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

.dialog.cols-4 {
  width: 1000px;
  margin-left: -500px;
}

  .dialog.cols-4 > section > div {
    flex: 0 0 25%;
    max-width: 25%;
  }

.forecast-graph {
  width: 300px;
  height: 300px;
  display: inline-block;
  float: left;
}

  .forecast-graph .dropdown-menu.show {
    padding: 5px;
  }

.list-control {
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 300px;
}

  .list-control > input {
    margin: 3px;
    width: 98%;
  }

  .list-control ul {
    height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
  }

    .list-control ul li {
      border-bottom: 1px solid #ccc;
      background: #f0f0f0;
      padding: 3px;
      cursor: pointer;
    }


.tree-control {
  max-height: 650px;
  background: #f6f8fa;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 13px;
  overflow-x: hidden;
  overflow-y: scroll;
}

  .tree-control input {
    margin: 3px;
    width: 98%;
  }

  .tree-control ul {
    list-style-type: none;
  }

    .tree-control ul li {
      padding-left: 10px;
    }

  .tree-control > ul {
    margin: 0;
    padding: 5px;
  }

  .tree-control a {
    color: #00f;
    cursor: pointer;
  }

.warning-limit {
  color: #FF0000;
  font-weight: bold;
  font-size: 15px;
}

.average-limit {
  color: #FF9800;
  font-weight: bold;
  font-size: 15px;
}

.container-fluid.mtd-report {
  display: table;
}

.counter {
  border-radius: 4px;
  color: #20498a;
  background: #20498a17;
  padding: 4px !important;
  display: initial;
}

.loading-status {
  padding-left: 5px;
}

.selected-category {
  font-weight: bold;
}

.drop-zone {
  width: 100%;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
}

  .drop-zone > div {
    margin-top: -20px;
  }

.virtual-input-box {
  border: 1px solid #ccc;
  display: block;
  line-height: 30px;
  min-height: 35px;
  margin-bottom: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.app-name {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 150px;
  color: #f8f9fa;
  text-shadow: 1px 1px #CCC;
  padding-top: 100px;
  min-height: 500px;
  user-select: none;
}

span.loading-span {
  min-width: 80px;
  margin: 5px 0;
  display: block;
  height: 20px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

span.loading-span-sm {
  min-width: 50px;
  margin: 5px 0;
  display: block;
  height: 20px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.site-header img {
  width: auto;
  height: 45px;
}

.site-header .dropdown a.dropdown-toggle {
  line-height: 17px;
}

.inline-block-element {
  display: inline-block;
}

.required {
  color: #f00;
  font-weight: bold;
}

.client-name {
  right: 1em;
  font-weight: bold;
  display: inline-block;
  font-size: 18px;
  line-height: inherit;
}

.width-30 {
  width: 30%;
}

.cursor-pointer {
  cursor: pointer;
}

.table thead th {
  cursor: pointer;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.dselect {
  display: block;
  border-color: green;
  border-style: solid;
}

.dempty {
  display: none;
  border-style: none;
}

.dashboardsquare {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, .2);
}

.mtdsquare {
  font-weight: bold;
  font-size: 110%
}

.warning-message {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 10px;
  border-radius: 10px;
}
/*Custom css*/


.option-content {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset;
  width: 100% !important;
}

.name {
  flex-grow: 1;
}

.counter {
  margin-left: 5px;
  margin-right: 5px;
}

.required {
  color: red;
  margin-left: 5px;
}

.icon-btn {
  margin-left: auto;
}

.edit-delet-btn {
  width: 1.125rem !important;
  height: 1.125rem;
}

.bottom-border {
  border-bottom: 1px solid #20488A;
  padding: 0 12px 8px 12px;
  background: #EFF5FF !important;
  border-radius: 4px 4px 0px 0px !important;
}

.bottom-border-without-bg {
  border-bottom: 1px solid #20488A !important;
}

/** Custom Input field **/

.custom-input-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  height: 2.5625rem;
  border: 1px solid rgba(70, 78, 97, 0.42);
  border-radius: 4px;
}

.custom-textarea-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  min-height: 2.5625rem;
  border: 1px solid rgba(70, 78, 97, 0.42);
  border-radius: 4px;
}

.custom-input::placeholder {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: rgba(70, 78, 97, 0.87);
}

.custom-input {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: rgba(70, 78, 97);
  width: 100%;
}

.color-input-wrapper {
  height: 1.5em;
  width: 1.5em;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

  .color-input-wrapper input[type=color] {
    position: absolute;
    height: 4em;
    width: 4em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border: none;
    margin: 0;
    padding: 0;
  }
/** Mat dialog container  **/
.mat-dialog-container {
  background: #FFFFFF !important;
  box-shadow: 0px 0px 54px 7px rgba(202, 204, 208, 0.33) !important;
  border-radius: 13px !important;
}
/** Table style**/
.table-container {
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
}

table th:first-child {
  padding-left: 16px !important;
}


table td:first-child {
  padding-left: 16px !important;
}


.selected-table-row {
  background: #20488A !important;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12) !important;
  color: #ffffff;
}


.mat-sort-header-button-custom {
  font-weight: 700 !important;
  line-height: 1.5rem !important;
  color: #212529 !important;
  background-color: #F6F8FA !important;
}


.action-header {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 21px 15px;
}

.table-margin-top {
  margin-top: 20px;
}

. {
  padding: 5px;
}

  .standard-margin-left {
    margin-left: 21px;
  }

  /* tree customization */

  .example-tree-invisible {
    display: none;
  }

  .example-tree ul,
  .example-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .example-tree-node .mat-button {
    padding: 0 !important;
  }

  .example-tree-node {
    display: block;
  }

    .example-tree-node .example-tree-node {
      padding-left: 40px;
    }

  .visibility-hidden {
    visibility: hidden;
  }

  .primary-action-btm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 36.5px !important;
    gap: 1rem;
    height: 2.75rem !important;
    background-color: #20488A !important;
    border-radius: 44px;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF !important;
  }

  .action-success-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 26.5px !important;
    gap: 1rem;
    height: 2.75rem !important;
    background-color: #57FF54 !important;
    border-radius: 44px;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF !important;
  }

  .action-cancel-btn {
    font-weight: 500;
    font-size: 0.875rem !important;
    line-height: 2.25rem !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FF5454;
  }

  /* Authentication */
  .auth-container {
    min-height: 97vh;
    overflow: hidden !important;
  }

  .sign-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 55px 1px rgba(32, 72, 138, 0.04);
    border-radius: 14px;
    padding: 25px;
    width: 21.0625rem;
    position: relative;
  }

  .sign-avatar {
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .sign-avtar img {
    width: 8.25rem !important;
    height: 8.25rem !important;
  }


  .sign-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-top: 45px;
  }

  .forgot-password-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #20488A;
  }

  .keep-me-sign-in-text {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-transform: capitalize;
    color: #333333;
  }

  .login-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 1rem;
    width: 17.9375rem;
    height: 2.75rem;
    margin-top: 31px !important;
    background-color: #20488A !important;
    border-radius: 44px;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF !important;
  }

  .sign-up-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px !important;
    gap: 1rem;
    width: 17.9375rem !important;
    height: 2.75rem !important;
    border: 1px solid #20488A;
    border-radius: 44px;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #20488A !important;
  }


  /** Header ***/

  .header-option-margin-left {
    margin-left: 50px;
  }

  .admin-logo {
    width: 5rem !important;
    height: 5rem !important;
  }
  /*** Formulas **/
  .search-image {
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-right: 10px;
  }

  .width-set {
    width: 18.0625rem;
  }

  .cell-circle {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .heading-text {
    font-weight: 600;
    font-size: 1.125rem !important;
    line-height: 1.3125rem;
    text-transform: capitalize;
    color: #333333;
  }

  .submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px !important;
    background-color: #20488A !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF !important;
  }

  .clear-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px !important;
    gap: 1rem;
    background-color: #F5F5F5 !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .create-form-group-width-set {
    width: 22.6875rem;
  }

  .form-width-set {
    width: 46.5625rem;
  }
  /** Invoices View **/
  .filter-action-width {
    width: 11.9375rem !important;
  }

  .total-invoices {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 2.25rem;
    text-transform: capitalize;
    color: #8E8E8E;
  }

  .total-invoices-value {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 2.25rem;
    text-transform: capitalize;
    color: #464E61;
  }

  .invoice-header {
    padding: 20px 20px 36px 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .invoice-margin-top {
    margin-top: 10px;
  }

  .invoice-text {
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-transform: capitalize;
    color: #333333;
  }

  .fw-400 {
    font-weight: 400;
  }

  .fw-600 {
    font-weight: 600;
  }

  .fw-700 {
    font-weight: 700;
  }

  /** Month to date**/

  .trending-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 23px;
    background: #20488A;
    border-radius: 28px;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 2.25rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF !important;
  }


  /* Performance summary */
  .progress-color-circle {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
  }

  .chart-canva-container {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 14px 20px;
  }

  /* Rank */
  .card-container {
    border-radius: 10px;
    background: #FFF;
  }

  .text-regular {
    color: #333;
    font-size: .875rem;
    line-height: normal;
  }

  .text-medium {
    color: #333;
    font-size: 1rem;
    line-height: normal;
  }

  .text-large {
    color: #333;
    font-size: 1.125rem;
    line-height: normal;
  }



  .text-small {
    color: #333;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .close-button {
    float: right;
    top: -24px;
    right: -24px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: unset;
  }

  .margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  /* Role manager */
  .create-role-width-set {
    width: 45.5625rem;
  }

  .custom-tab-active {
    display: flex;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    min-width: 142px;
    color: #20488A;
    font-size: .875rem;
    border-bottom: 2px solid #20488A;
    cursor: pointer;
    text-transform: uppercase;
  }

  .custom-tab-unactive {
    display: flex;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    min-width: 142px;
    font-size: .875rem;
    color: black/0.54;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
  }

  /* Import */

  .ngx-file-drop__drop-zone {
    border-radius: 7px !important;
    border: 1px dashed #20488A !important;
    background: #EFF5FF !important;
    height: 60px !important;
  }

  .ngx-file-drop__content {
    align-items: center;
    color: #20488A !important;
    display: flex;
    height: 60px !important;
    font-size: 1rem !important;
    justify-content: center;
  }

  .glyphicon .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: #333333;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 1rem;
    position: absolute;
    top: -40px;
    z-index: 999;
  }

  .glyphicon:hover .tooltiptext {
    visibility: visible;
  }

  .text-wrap {
    text-wrap: wrap;
  }

  .yellow a:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }

  .yellow a:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }

  .mat-column-imei,
  .mat-column-imeiForDisplay,
  .mat-column-serialNumber,
  .mat-column-productName {
    max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-row .mat-column-imei,
  .mat-row .mat-column-imeiForDisplay,
  .mat-row .mat-column-serialNumber,
  .mat-row .mat-column-productName {
    max-width: fit-content !important;
  }
