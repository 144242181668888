
.mat-row[_ngcontent-mch-c386]:hover .mat-cell[_ngcontent-mch-c386] {
  /*background: #20488A;*/
}

.mat-icon.mat-warn {
  color: #45C25F
}

.yellow .mat-checkbox-frame {
  /*border-color: #fff !important*/
}

.yellow a {
  /*color: #fff !important*/
}

.mat-row:hover .mat-cell {
  /*background-color: rgba(192, 212, 247, 0.4);*/ /*Light blueish highlight */
  background-color: rgba(143, 173, 215, 0.4);
  /*color: #fff*/
}


.mat-cell {
  overflow: hidden;
  max-width: 4rem;
  text-overflow: ellipsis;
  background-color: #FAFAFA;
  padding: 2px 10px 2px 10px;
  hyphens: auto;
  white-space: nowrap;
  font-size: 12px;
}

.mat-row:hover a {
/*  color: #fff !important;*/
}

.mat-paginator-page-size-select {
  width: auto !important;
  margin: unset !important
}

.mat-paginator{
    border-end-end-radius: 4px;
    border-end-start-radius:4px;
}
.mat-paginator-container {
    padding: 0 8px 15px 8px !important;
}
.mat-paginator-range-actions {
  margin-top: 15px !important;
}
  .mat-paginator-page-size-select .mat-form-field-infix {
    width: 52px !important
  }

.mat-icon.mat-primary {
  color: #C24545
}

.mat-toolbar.mat-primary, .custom-bread-crumb, .mat-drawer-container {
  background: #EFF5FF !important;
}

.mat-drawer-container {
  min-height: 100vh !important;
}

.custom-bread-crumb {
  padding-left: 25px !important;
}

.navbar-brand {
  color: #20488A !important;
  font-size: 24px;
  font-weight: 600;
}

.mat-icon-button .mat-icon {
  color: #000
}

.mat-select-value-text, .client-name, .location-dropdown {
  color: #333;
  font-size: 14px
}

.mat-form-field-infix {
  color: #8E8E8E
}

.mat-expansion-panel {
  background: #20488A;
}

.body-container, .mat-app-background {
  background: transparent !important;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description, .mat-expansion-indicator::after, .docs-component-viewer-nav-content[_ngcontent-uru-c135] .mat-list-item[_ngcontent-uru-c135] {
  color: #fff;
}

.custom-bread-crumb li {
  color: #333;
  font-size: 18px;
  font-weight: 600
}

.mat-app-background {
  padding: 0 12px;
}

.mat-elevation-z8 {
  box-shadow: unset;
}


.setborder {
  border-bottom: #333 1px solid
}

.mat-form-field {
  display: inherit !important;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  background: #fff;
  border-radius: 4px;
  margin-bottom: .5rem
}

.mat-list-base .mat-list-i {
  padding: 8px 0;
  color: #fff;
}

.mat-list-base .mat-list-item {
  color: #fff;
  padding: 10px 0 !important;
}

.mat-drawer:not(.mat-drawer-side) {
  background-color: #20488a;
}

.table-bordered td {
  border: none;
  border-bottom: #d6cece 1px solid !important
}

.mat-button-focus-overlay {
  background: transparent !important
}

.flex-container > .flex-items {
  width: 22.22%
}

  .flex-container > .flex-items .checkbox-inline {
    display: inline-block;
  }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background: unset !important; 
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: unset !important;
  width:100%;
}

/*.mat-form-field-flex {
  padding:0 12px 8px 12px;
  background: #EFF5FF !important;
  border-radius: 4px 4px 0px 0px !important;
}*/

.searchtab {
  background: #EFF5FF;
  border-bottom: #333 1px solid;
  padding: 0.5em;
}

.mat-form-field-ripple {
  background: transparent !important
}
/*padding command margin */
.pt-20 {
  padding-top: 20px !important;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label {
  text-transform: uppercase;
  color: #000
}

.mat-tab-group.mat-background-primary > .mat-tab-header {
  background: #fff
}
.mat-tab-labels {
  display: flex;
  justify-content: space-between;
}



.mat-tab-list .mat-tab-labels .mat-tab-label-active {
  border-bottom: 5px solid #3F51B5;
  color: #3F51B5 !important
}

.charge-btn {
  background: #C7D7F0 !important;
  color: #0047BB !important;
}

.dismiss-btn {
  background: #D2BFF0 !important;
  color: #6900BB !important
}

.discrep-btn {
  background: #C7F0EB !important;
  color: #00BBA5 !important
}

.export-btn {
  background: #D4F0C7 !important;
  color: #3CBB00 !important
}

.back-btn {
  background: #faee8a !important;
  color: #be9e1c !important;
}

.mat-tab-label .mat-tab-label-content {
  font-weight: 600
}

.mat-tab-body-content{
   overflow: hidden !important;
}

.pr-0 {
  padding-right: 0
}
.pr-1 {
  padding-right: 1em !important;
}
.table thead th {
  font-weight: 700 !important
}


.mat-sort-header-content {
  text-align: left !important
}

.mat-toolbar h1 {
  font-family: 'Roboto', sans-serif !important;

  font-size: 24px;
  font-weight: 800
}

.mat-form-field {
  font-family: 'Roboto', sans-serif !important;
}

.mat-input-element {
  color: #464E61 !important
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  font-weight: 600;
  padding-top:8px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #20488a !important;
}

.setrq {
  text-align: right;
  float: right;
  font-weight: bold;
  margin-right: 40px;
}

.mat-error {
  font-size: 12px !important;
  font-weight: 500;
}

input.mat-input-element {
    padding-top:8px;
}

.mat-select {
    padding-top:8px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3F51B5 !important;
}

/* Override the default primary color when the radio button is checked */
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: #3F51B5 !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0,0,0,.04) !important;
}

.font-size-14{
    font-size:14px !important;
}

tr.mat-header-row{
    height:48px !important;
}
tr.mat-row, tr.mat-footer-row{
    height:42px !important;
}
